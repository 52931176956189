import { Error, Email, ID, Timestamp } from "types/common"
import { User } from "types/user"
import { Provider, ProviderID } from "types/providers"

/**
 * NOTIFICATION TYPES
 */
export type InvitationID = ID
export type InvitationStatus = "invited" | "accepted" | "rejected"

export interface Invitation {
  id: InvitationID
  invited_by: User
  provider: ProviderID | Provider
  status?: InvitationStatus
  email: Email
  created_at: Timestamp
  updated_at: Timestamp
}

export interface NotificationsState {
  isLoading: boolean
  isDeleting: boolean
  error: Error
  invitations: Record<InvitationID, Invitation>
  //  providers: Record<ProviderID, Record<InvitationID, Invitation>> // mapping of provider ID to mapping of ID to invitation
  //  data: Record<string, any>
  //  initialized: boolean
}

export enum NotificationActionType {
  CLEAR_USER_NOTIFICATION_STATE = "@@notifications/CLEAR_USER_NOTIFICATION_STATE",

  FETCHING_USER_INVITATIONS = "@@notifications/FETCHING_USER_INVITATIONS",
  FETCHING_USER_INVITATIONS_SUCCESS = "@@notifications/FETCHING_USER_INVITATIONS_SUCCESS",
  FETCHING_USER_INVITATIONS_FAILURE = "@@notifications/FETCHING_USER_INVITATIONS_FAILURE",

  SEND_USER_INVITATION_TO_TEAM = "@@notifications/SEND_USER_INVITATION_TO_TEAM",
  SEND_USER_INVITATION_TO_TEAM_SUCCESS = "@@notifications/SEND_USER_INVITATION_TO_TEAM_SUCCESS",
  SEND_USER_INVITATION_TO_TEAM_FAILURE = "@@notifications/SEND_USER_INVITATION_TO_TEAM_FAILURE",

  ACCEPT_TEAM_INVITE_THROUGH_JOIN_CODE = "@@notifications/ACCEPT_TEAM_INVITE_THROUGH_JOIN_CODE",
  ACCEPT_TEAM_INVITE_THROUGH_JOIN_CODE_SUCCESS = "@@notifications/ACCEPT_TEAM_INVITE_THROUGH_JOIN_CODE_SUCCESS",
  ACCEPT_TEAM_INVITE_THROUGH_JOIN_CODE_FAILURE = "@@notifications/ACCEPT_TEAM_INVITE_THROUGH_JOIN_CODE_FAILURE",

  LIST_ALL_INVITATIONS_FOR_PROVIDER = "@@notifications/LIST_ALL_INVITATIONS_FOR_PROVIDER",
  LIST_ALL_INVITATIONS_FOR_PROVIDER_SUCCESS = "@@notifications/LIST_ALL_INVITATIONS_FOR_PROVIDER_SUCCESS",
  LIST_ALL_INVITATIONS_FOR_PROVIDER_FAILURE = "@@notifications/LIST_ALL_INVITATIONS_FOR_PROVIDER_FAILURE",

  CACHE_PROVIDER_INVITATIONS_LOCALLY = "@@notifications/CACHE_PROVIDER_INVITATIONS_LOCALLY",

  USER_ACCEPTS_INVITATION = "@@notifications/USER_ACCEPTS_INVITATION",
  USER_ACCEPTS_INVITATION_SUCCESS = "@@notifications/USER_ACCEPTS_INVITATION_SUCCESS",
  USER_ACCEPTS_INVITATION_FAILURE = "@@notifications/USER_ACCEPTS_INVITATION_FAILURE",

  USER_REJECTS_INVITATION = "@@notifications/USER_REJECTS_INVITATION",
  USER_REJECTS_INVITATION_SUCCESS = "@@notifications/USER_REJECTS_INVITATION_SUCCESS",
  USER_REJECTS_INVITATION_FAILURE = "@@notifications/USER_REJECTS_INVITATION_FAILURE",

  DELETE_INVITATION_TO_PROVIDER = "@@notifications/DELETE_INVITATION_TO_PROVIDER",
  DELETE_INVITATION_TO_PROVIDER_SUCCESS = "@@notifications/DELETE_INVITATION_TO_PROVIDER_SUCCESS",
  DELETE_INVITATION_TO_PROVIDER_FAILURE = "@@notifications/DELETE_INVITATION_TO_PROVIDER_FAILURE",
}

// GENERIC NOTIFICATION ACTIONS
export interface IClearUserNotificationState {
  type: NotificationActionType.CLEAR_USER_NOTIFICATION_STATE
}
export interface ICacheProviderInvitationsLocally {
  type: NotificationActionType.CACHE_PROVIDER_INVITATIONS_LOCALLY
  providerId: ID
  data: Invitation[]
}
export type GenericNotificationAction = IClearUserNotificationState | ICacheProviderInvitationsLocally

// FETCH USER INVITATIONS
export interface IFetchUserInvitations {
  type: NotificationActionType.FETCHING_USER_INVITATIONS
}
export interface IFetchUserInvitationsSuccess {
  type: NotificationActionType.FETCHING_USER_INVITATIONS_SUCCESS
  data: Invitation[]
}
export interface IFetchUserInvitationsFailure {
  type: NotificationActionType.FETCHING_USER_INVITATIONS_FAILURE
  error: Error
}
export type FetchUserInvitationsAction =
  | IFetchUserInvitations
  | IFetchUserInvitationsSuccess
  | IFetchUserInvitationsFailure

// SEND USER INVITATION TO TEAM
export interface ISendUserInvitationToTeam {
  type: NotificationActionType.SEND_USER_INVITATION_TO_TEAM
}
export interface ISendUserInvitationToTeamSuccess {
  type: NotificationActionType.SEND_USER_INVITATION_TO_TEAM_SUCCESS
  data: Invitation
}
export interface ISendUserInvitationToTeamFailure {
  type: NotificationActionType.SEND_USER_INVITATION_TO_TEAM_FAILURE
  error: Error
}
export type SendUserInvitationToTeamAction =
  | ISendUserInvitationToTeam
  | ISendUserInvitationToTeamSuccess
  | ISendUserInvitationToTeamFailure

// ACCEPT TEAM INVITE THROUGH JOIN CODE
export interface IAcceptTeamInviteThroughJoinCode {
  type: NotificationActionType.ACCEPT_TEAM_INVITE_THROUGH_JOIN_CODE
}
export interface IAcceptTeamInviteThroughJoinCodeSuccess {
  type: NotificationActionType.ACCEPT_TEAM_INVITE_THROUGH_JOIN_CODE_SUCCESS
}
export interface IAcceptTeamInviteThroughJoinCodeFailure {
  type: NotificationActionType.ACCEPT_TEAM_INVITE_THROUGH_JOIN_CODE_FAILURE
  error: Error
}
export type AcceptTeamInviteThroughJoinCodeAction =
  | IAcceptTeamInviteThroughJoinCode
  | IAcceptTeamInviteThroughJoinCodeSuccess
  | IAcceptTeamInviteThroughJoinCodeFailure

// LIST ALL INVITATIONS FOR PROVIDER
export interface IListAllInvitationsForProvider {
  type: NotificationActionType.LIST_ALL_INVITATIONS_FOR_PROVIDER
}
export interface IListAllInvitationsForProviderSuccess {
  type: NotificationActionType.LIST_ALL_INVITATIONS_FOR_PROVIDER_SUCCESS
}
export interface IListAllInvitationsForProviderFailure {
  type: NotificationActionType.LIST_ALL_INVITATIONS_FOR_PROVIDER_FAILURE
  error: Error
}
export type ListAllInvitationsForProviderAction =
  | IListAllInvitationsForProvider
  | IListAllInvitationsForProviderSuccess
  | IListAllInvitationsForProviderFailure

// USER ACCEPTS INVITATION
export interface IUserAcceptsInvitation {
  type: NotificationActionType.USER_ACCEPTS_INVITATION
}
export interface IUserAcceptsInvitationSuccess {
  type: NotificationActionType.USER_ACCEPTS_INVITATION_SUCCESS
}
export interface IUserAcceptsInvitationFailure {
  type: NotificationActionType.USER_ACCEPTS_INVITATION_FAILURE
  error: Error
}
export type UserAcceptsInvitationAction =
  | IUserAcceptsInvitation
  | IUserAcceptsInvitationSuccess
  | IUserAcceptsInvitationFailure

// USER REJECTS INVITATION
export interface IUserRejectsInvitation {
  type: NotificationActionType.USER_REJECTS_INVITATION
}
export interface IUserRejectsInvitationSuccess {
  type: NotificationActionType.USER_REJECTS_INVITATION_SUCCESS
}
export interface IUserRejectsInvitationFailure {
  type: NotificationActionType.USER_REJECTS_INVITATION_FAILURE
  error: Error
}
export type UserRejectsInvitationAction =
  | IUserRejectsInvitation
  | IUserRejectsInvitationSuccess
  | IUserRejectsInvitationFailure

// DELETE INVITATION TO PROVIDER
export interface IDeleteInvitationToProvider {
  type: NotificationActionType.DELETE_INVITATION_TO_PROVIDER
}
export interface IDeleteInvitationToProviderSuccess {
  type: NotificationActionType.DELETE_INVITATION_TO_PROVIDER_SUCCESS
  data: Invitation
}
export interface IDeleteInvitationToProviderFailure {
  type: NotificationActionType.DELETE_INVITATION_TO_PROVIDER_FAILURE
  error: Error
}
export type DeleteInvitationToProviderAction =
  | IDeleteInvitationToProvider
  | IDeleteInvitationToProviderSuccess
  | IDeleteInvitationToProviderFailure

export type NotificationAction =
  | GenericNotificationAction
  | FetchUserInvitationsAction
  | SendUserInvitationToTeamAction
  | AcceptTeamInviteThroughJoinCodeAction
  | ListAllInvitationsForProviderAction
  | UserAcceptsInvitationAction
  | UserRejectsInvitationAction
  | DeleteInvitationToProviderAction
