import * as R from "ramda"
import { createSelector } from "reselect"
import { ThunkAction } from "redux-thunk"
import apiClient, { ApiClientCallbackResponse } from "services/apiClient"

import initialState, { StateShape } from "models/initialState"
// import { User } from "types/user"
import { Actions as uiActions } from "models/ui"
import { Provider, ProviderID } from "types/providers"

import { extractErrorMessages } from "utils/errors"

import { ToastType } from "types/ui"
import { ID, Email, Error, Timestamp } from "types/common"
import { GenericAuthAction, AuthActionType } from "types/auth"
import {
  InvitationID,
  NotificationAction,
  NotificationActionType,
  FetchUserInvitationsAction,
  SendUserInvitationToTeamAction,
  AcceptTeamInviteThroughJoinCodeAction,
  UserAcceptsInvitationAction,
  UserRejectsInvitationAction,
  DeleteInvitationToProviderAction,
  GenericNotificationAction,
} from "types/notifications"

export default function notificationsReducer(
  state = initialState.notifications,
  action: NotificationAction | GenericAuthAction
) {
  switch (action.type) {
    case NotificationActionType.FETCHING_USER_INVITATIONS:
      return {
        ...state,
        isLoading: true,
      }
    case NotificationActionType.FETCHING_USER_INVITATIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        invitations: {
          ...state.invitations,
          ...(R.indexBy(R.prop("id"), action.data) || {}),
        },
        error: null,
        initialized: true,
      }
    case NotificationActionType.FETCHING_USER_INVITATIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        initialized: true,
      }
    // case NotificationActionType.SEND_USER_INVITATION_TO_TEAM:
    //   return {
    //     ...state,
    //     isLoading: true,
    //   }
    // case NotificationActionType.SEND_USER_INVITATION_TO_TEAM_SUCCESS:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     error: null,
    //     providers: {
    //       ...state.providers,
    //       [action.data.provider as string]: {
    //         ...(state.providers?.[action.data.provider as string] || {}),
    //         [action.data.id]: action.data,
    //       },
    //     },
    //   }
    // case NotificationActionType.SEND_USER_INVITATION_TO_TEAM_FAILURE:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     error: action.error,
    //   }
    case NotificationActionType.USER_ACCEPTS_INVITATION:
      return {
        ...state,
        isLoading: true,
      }
    case NotificationActionType.USER_ACCEPTS_INVITATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
      }
    case NotificationActionType.USER_ACCEPTS_INVITATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      }
    case NotificationActionType.USER_REJECTS_INVITATION:
      return {
        ...state,
        isLoading: true,
      }
    case NotificationActionType.USER_REJECTS_INVITATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
      }
    case NotificationActionType.USER_REJECTS_INVITATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      }
    // case NotificationActionType.LIST_ALL_INVITATIONS_FOR_PROVIDER:
    //   return {
    //     ...state,
    //     isLoading: true,
    //   }
    // case NotificationActionType.LIST_ALL_INVITATIONS_FOR_PROVIDER_SUCCESS:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     activeProviderInvitationsFetched: true,
    //     error: null,
    //   }
    // case NotificationActionType.LIST_ALL_INVITATIONS_FOR_PROVIDER_FAILURE:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     error: action.error,
    //     activeProviderInvitationsFetched: true,
    //   }
    // case NotificationActionType.DELETE_INVITATION_TO_PROVIDER:
    //   return {
    //     ...state,
    //     isDeleting: true,
    //   }
    // case NotificationActionType.DELETE_INVITATION_TO_PROVIDER_SUCCESS:
    //   const providerInvitations = state.providers?.[action.data.provider as string]

    //   return {
    //     ...state,
    //     isDeleting: false,
    //     error: null,
    //     providers: {
    //       ...state.providers,
    //       [action.data.provider as string]: {
    //         ...R.indexBy(
    //           R.prop("id"),
    //           R.filter((invite) => invite.id !== action.data.id, R.values(providerInvitations))
    //         ),
    //       },
    //     },
    //   }
    // case NotificationActionType.DELETE_INVITATION_TO_PROVIDER_FAILURE:
    //   return {
    //     ...state,
    //     isDeleting: false,
    //     error: action.error,
    //   }
    // case NotificationActionType.CACHE_PROVIDER_INVITATIONS_LOCALLY:
    //   return {
    //     ...state,
    //     providers: {
    //       ...state.providers,
    //       // this would merge current invitations and new invitations
    //       // [action.providerId]: {
    //       //   ...(state.providers?.[action.providerId] || {}),
    //       //   ...(R.indexBy(R.prop("id"), action.data))
    //       // }
    //       // this would overwrite all local invitations with invites from server
    //       [action.providerId]: {
    //         ...R.indexBy(R.prop("id"), action.data),
    //       },
    //     },
    //   }
    case NotificationActionType.CLEAR_USER_NOTIFICATION_STATE:
      return initialState.notifications
    case AuthActionType.SIGN_USER_OUT:
      return initialState.notifications
    default:
      return state
  }
}

/**
 * NOTIFICATION ACTIONS
 */

const clearUserNotificationState = () => ({ type: NotificationActionType.CLEAR_USER_NOTIFICATION_STATE })

export type FetchUserInvitationsResult = ThunkAction<
  Promise<ApiClientCallbackResponse>,
  StateShape,
  void,
  FetchUserInvitationsAction
>
const fetchUserInvitations = (): FetchUserInvitationsResult => {
  return (dispatch) => {
    return dispatch(
      apiClient({
        url: `/invitations/me/`,
        method: "GET",
        types: {
          REQUEST: NotificationActionType.FETCHING_USER_INVITATIONS,
          SUCCESS: NotificationActionType.FETCHING_USER_INVITATIONS_SUCCESS,
          FAILURE: NotificationActionType.FETCHING_USER_INVITATIONS_FAILURE,
        },
        options: {
          data: {},
          params: {},
        },
        onSuccess: (res) => {
          return { success: true, status: res.status }
        },
        onFailure: (res) => {
          return { success: false, status: res.status, error: res.error }
        },
      })
    )
  }
}

export type SendUserInvitationParams = { email: Email; provider: Provider }
export type SendUserInvitationResult = ThunkAction<
  Promise<ApiClientCallbackResponse>,
  StateShape,
  void,
  SendUserInvitationToTeamAction
>
const sendUserInvitationToTeam = ({ email, provider }: SendUserInvitationParams): SendUserInvitationResult => {
  return (dispatch) => {
    return dispatch(
      apiClient({
        url: `/invitations/providers/${provider.id}/`,
        method: "POST",
        types: {
          REQUEST: NotificationActionType.SEND_USER_INVITATION_TO_TEAM,
          SUCCESS: NotificationActionType.SEND_USER_INVITATION_TO_TEAM_SUCCESS,
          FAILURE: NotificationActionType.SEND_USER_INVITATION_TO_TEAM_FAILURE,
        },
        options: {
          data: { invitation_create: { email } },
          params: {},
        },
        onSuccess: (res) => {
          return { success: true, status: res.status, res }
        },
        onFailure: (res) => {
          const error = res?.error?.response && res?.error?.response?.data ? res.error.response.data : res.error

          const errorMessages = extractErrorMessages(error)
          const toast = {
            title: `Invitation unsuccessful`,
            contents: errorMessages[0],
            // type: `danger`,
            type: ToastType.DANGER,
          }
          dispatch(uiActions.addUiToast({ toast }))
          return { success: false, status: res.status, error: res.error }
        },
      })
    )
  }
}

export type AcceptJoinTokenTeamInvitationParams = { token: string }
export type AcceptJoinTokenTeamInvitationResult = ThunkAction<
  Promise<ApiClientCallbackResponse>,
  StateShape,
  void,
  AcceptTeamInviteThroughJoinCodeAction
>
const acceptTeamInviteViaJoinToken = ({
  token,
}: AcceptJoinTokenTeamInvitationParams): AcceptJoinTokenTeamInvitationResult => {
  return (dispatch) => {
    return dispatch(
      apiClient({
        url: `/invitations/token/accept/`,
        method: "POST",
        types: {
          REQUEST: NotificationActionType.ACCEPT_TEAM_INVITE_THROUGH_JOIN_CODE,
          SUCCESS: NotificationActionType.ACCEPT_TEAM_INVITE_THROUGH_JOIN_CODE_SUCCESS,
          FAILURE: NotificationActionType.ACCEPT_TEAM_INVITE_THROUGH_JOIN_CODE_FAILURE,
        },
        options: {
          data: {},
          params: { token },
        },
        onSuccess: (res) => {
          return { success: true, status: res.status, res }
        },
        onFailure: (res) => {
          const error = res?.error?.response && res?.error?.response?.data ? res.error.response.data : res.error

          const errorMessages = extractErrorMessages(error)
          const toast = {
            title: `Unsuccessful Join`,
            contents: errorMessages[0],
            // type: `danger`,
            type: ToastType.DANGER,
          }
          dispatch(uiActions.addUiToast({ toast }))
          return { success: false, status: res.status, error: res.error }
        },
      })
    )
  }
}

export type AcceptDeleteOrRejectInvitationParams = { invitationId: InvitationID }
export type AcceptDeleteOrRejectInvitationResult = ThunkAction<
  Promise<ApiClientCallbackResponse>,
  StateShape,
  void,
  UserAcceptsInvitationAction
>
const acceptInvitation = ({
  invitationId,
}: AcceptDeleteOrRejectInvitationParams): AcceptDeleteOrRejectInvitationResult => {
  return (dispatch) => {
    return dispatch(
      apiClient({
        url: `/invitations/${invitationId}/accept/`,
        method: "POST",
        types: {
          REQUEST: NotificationActionType.USER_ACCEPTS_INVITATION,
          SUCCESS: NotificationActionType.USER_ACCEPTS_INVITATION_SUCCESS,
          FAILURE: NotificationActionType.USER_ACCEPTS_INVITATION_FAILURE,
        },
        options: {
          data: {},
          params: {},
        },
        onSuccess: (res) => {
          dispatch(Actions.fetchUserInvitations())

          return { success: true, status: res.status, data: res.data }
        },
        onFailure: (res) => {
          // const error = res?.error?.response && res?.error?.response?.data ? res.error.response.data : res.error

          // const errorMessages = extractErrorMessages(error)
          const toast = {
            title: `Invitation Error`,
            // contents: errorMessages[0],
            contents: `We were unable to complete your request to join the team. The offer may have been rescinded.`,
            // type: `danger`,
            type: ToastType.DANGER,
          }
          dispatch(uiActions.addUiToast({ toast }))

          return { success: false, status: res.status, error: res.error }
        },
      })
    )
  }
}

export type RejectInvitationResult = ThunkAction<
  Promise<ApiClientCallbackResponse>,
  StateShape,
  void,
  UserRejectsInvitationAction
>
const rejectInvitation = ({ invitationId }: AcceptDeleteOrRejectInvitationParams): RejectInvitationResult => {
  return (dispatch) => {
    return dispatch(
      apiClient({
        url: `/invitations/${invitationId}/reject/`,
        method: "POST",
        types: {
          REQUEST: NotificationActionType.USER_REJECTS_INVITATION,
          SUCCESS: NotificationActionType.USER_REJECTS_INVITATION_SUCCESS,
          FAILURE: NotificationActionType.USER_REJECTS_INVITATION_FAILURE,
        },
        options: {
          data: {},
          params: {},
        },
        onSuccess: (res) => {
          dispatch(Actions.fetchUserInvitations())

          return { success: true, status: res.status, data: res.data }
        },
        onFailure: (res) => {
          // const error = res?.error?.response && res?.error?.response?.data ? res.error.response.data : res.error

          // const errorMessages = extractErrorMessages(error)
          const toast = {
            title: `Invitation Error`,
            // contents: errorMessages[0],
            contents: `We were unable to complete your request to reject joining the team. The offer may have been rescinded.`,
            // type: `danger`,
            type: ToastType.DANGER,
          }
          dispatch(uiActions.addUiToast({ toast }))

          return { success: false, status: res.status, error: res.error }
        },
      })
    )
  }
}

export type DeleteInvitationResult = ThunkAction<
  Promise<ApiClientCallbackResponse>,
  StateShape,
  void,
  DeleteInvitationToProviderAction
>
const deleteInvitation = ({ invitationId }: AcceptDeleteOrRejectInvitationParams): DeleteInvitationResult => {
  return (dispatch) => {
    return dispatch(
      apiClient({
        url: `/invitations/${invitationId}/`,
        method: `DELETE`,
        types: {
          REQUEST: NotificationActionType.DELETE_INVITATION_TO_PROVIDER,
          SUCCESS: NotificationActionType.DELETE_INVITATION_TO_PROVIDER_SUCCESS,
          FAILURE: NotificationActionType.DELETE_INVITATION_TO_PROVIDER_FAILURE,
        },
        options: {
          data: {},
          params: {},
        },
        onSuccess: (res) => {
          dispatch(Actions.fetchUserInvitations())

          return { success: true, status: res.status, data: res.data }
        },
        onFailure: (res) => {
          const error = res?.error?.response && res?.error?.response?.data ? res.error.response.data : res.error

          const errorMessages = extractErrorMessages(error)
          const toast = {
            title: `Could not delete invitation.`,
            contents: errorMessages[0],
            // type: `danger`,
            type: ToastType.DANGER,
          }
          dispatch(uiActions.addUiToast({ toast }))

          return { success: false, status: res.status, error: res.error }
        },
      })
    )
  }
}

export type ListInvitationsForProviderParams = { providerId: ProviderID }
export type ListInvitationsForProviderResult = ThunkAction<
  Promise<ApiClientCallbackResponse>,
  StateShape,
  void,
  DeleteInvitationToProviderAction | GenericNotificationAction
>
const listAllInvitationsForProvider = ({
  providerId,
}: ListInvitationsForProviderParams): ListInvitationsForProviderResult => {
  return (dispatch) => {
    return dispatch(
      apiClient({
        url: `/providers/${providerId}/invitations/`,
        method: `GET`,
        types: {
          REQUEST: NotificationActionType.LIST_ALL_INVITATIONS_FOR_PROVIDER,
          SUCCESS: NotificationActionType.LIST_ALL_INVITATIONS_FOR_PROVIDER_SUCCESS,
          FAILURE: NotificationActionType.LIST_ALL_INVITATIONS_FOR_PROVIDER_FAILURE,
        },
        options: {
          data: {},
          params: {},
        },
        onSuccess: (res) => {
          dispatch({
            type: NotificationActionType.CACHE_PROVIDER_INVITATIONS_LOCALLY,
            data: res.data,
            providerId,
          })

          return { success: true, status: res.status, data: res.data }
        },
        onFailure: (res) => {
          // const error = res?.error?.response && res?.error?.response?.data ? res.error.response.data : res.error

          // const errorMessages = extractErrorMessages(error)
          // const toast = {
          //   title: `Unsuccessful Join`,
          //   contents: errorMessages[0],
          //   type: `danger`,
          // }
          // dispatch(uiActions.addUiToast({ toast }))
          return { success: false, status: res.status, error: res.error }
        },
      })
    )
  }
}

export const Actions = {
  clearUserNotificationState,
  fetchUserInvitations,
  sendUserInvitationToTeam,
  acceptTeamInviteViaJoinToken,
  acceptInvitation,
  rejectInvitation,
  deleteInvitation,
  listAllInvitationsForProvider,
}

/**
 * NOTIFICATION SELECTORS
 */
// export const getActiveProvider = (state) => R.prop(state.providers.activeProviderId, state.providers.data)
// export const selectActiveProvider = createSelector([getActiveProvider], (activeProvider) => activeProvider)
//  export const getActiveProviderInvitations = (state: StateShape) => {
//    const activeProvider = selectActiveProvider(state)
//    const invites: Invitation[] = R.path(["notifications", "providers", activeProvider?.id], state)
//    return invites ? R.values(invites) : []
//  }
//  export const selectActiveProviderInvitations = createSelector(
//    [getActiveProviderInvitations],
//    (invitations) => invitations
//  )
